import { type CodeDiscountNodeByCode } from '~/graphql/shopify/queries/codeDiscounts'
import type {
  AutomaticDiscount,
  CustomerGets,
  DiscountType,
  DiscountAutomaticBxgy,
} from '~/graphql/shopify/queries/automaticDiscounts'
import type { CartLine } from '~/graphql/shopify/fragments/cartLines'

interface FreeGiftOptions {
  productId: string
  options: Array<{
    variantId: string
    sku: string
  } | null>
}

interface DiscountSummary {
  discountType: DiscountType
  discountPercentageOff: number
  discountAmountOff: number
  discountIsFree: boolean
}

interface DiscountsForProduct {
  anyDiscountThresholdPassed: boolean
  anyDiscountIsFree: boolean
  availableDiscounts: {
    thresholdForDiscountMet: boolean
    matchedItems: {
      id: string
      sku: string
      price: string
    }[]
    discountSummary: DiscountSummary
    customerGets: {
      productId: string
      options: {
        variantId: string
        baseSku: string
        sku: string
      }[]
    }[]
  }[]
}

export type CartIDRecord = {
  marketId: string
  cartId: string
}

export type CartAttributes = {
  key: string
  value: string
}[]

type BundleItem = {
  node: CartLine
}

export type Bundle = {
  isShopifyBundle: boolean
  bundleId: string
  items: BundleItem[]
  summary?: {
    bundleSku?: string
    bundleSkus: string[] | undefined
    bundleBaseSku?: string
    bundleCost: number
    bundleOriginalCost: number
    bundleCostDifference: number
    bundleQuantity: number
    bundleDisplayOrder: number
    bundleCheckoutInfo?: string[]
    bundleDespatchDate?: string
  }
}

export type BundledCartItems = {
  [key: string]: Bundle
}

export interface CouponResponse {
  success: boolean
  body?: any
  error?: string
}

/**
 *
 * @param error
 *
 * Captures error on Sentry and console logs error.
 *
 */
export function captureException(error: Error) {
  const { $sentry } = useNuxtApp()
  // eslint-disable-next-line no-unused-vars
  const sentry = $sentry as { captureException: (error: Error) => void }
  sentry.captureException(error)
  console.error(error)
}

/**
 *
 * @param bundledCartItems
 * @param customisedProduct
 *
 * Finds bundles already in cart where the bundle sku matches the sku of the customisedProduct.sku
 * or customisedProduct.secondarySKU
 *
 */
export function getExistingBundlesInCartFromSku(
  bundledCartItems: BundledCartItems,
  sku: string
) {
  return Object.values(bundledCartItems).filter((value) => {
    return getBundleSkuFromBundleItems(value.items) === sku
  })
}

/**
 *
 * @param productsToRemove
 * @param bundledCartItems
 *
 * Returns an array of Shopify cart item ids based on an array of skus
 *
 */
export function buildLineIdsToRemove(
  productsToRemove: string[],
  bundledCartItems: BundledCartItems
) {
  return (
    productsToRemove
      .map((sku) => {
        const matchedBundleItem = Object.values(bundledCartItems).find(
          (value) => {
            return getBundleSkuFromBundleItems(value.items) === sku
          }
        )

        if (matchedBundleItem) {
          if (matchedBundleItem.isShopifyBundle)
            return matchedBundleItem.bundleId
          return matchedBundleItem.items.map((item) => item.node.id)
        }
      })
      .flat() || []
  )
}

/**
 *
 * @param newLineItem
 * @param bundledCartItems
 * @param previousBundleId
 *
 * @returns number
 */
function calculateBundleDisplayOrder(
  newLineItem,
  bundledCartItems: BundledCartItems,
  previousBundleId: string
): number {
  const bundleDisplayOrder = Number(
    newLineItem.attributes.find((attr) => attr.key === '_bundleDisplayOrder')
      ?.value ||
      Object.entries(bundledCartItems).length + 1 ||
      0
  )

  const currentBundleId = newLineItem.attributes.find(
    (attr) => attr.key === '_bundleId'
  )?.value

  if (previousBundleId !== currentBundleId) {
    return bundleDisplayOrder + 1
  }

  return bundleDisplayOrder
}

/**
 *
 * @param bundledCartItems
 *
 * @param newLineItems
 *
 * Sets the _bundleDisplayOrder attribute on each of the line items based
 * on the number of items in the cart, incrementing the value by one based on
 * the number of bundled items already in the cart.
 *
 * If a cart item already has the _bundleDisplayOrder value it will use that
 * existing value.
 *
 * This is used to persist the order of the cart items when cart items are
 * swapped out for a different product e.g. upgrading a cart item.
 *
 */
export function buildCartItemsWithBundleDisplayOrder(
  bundledCartItems: BundledCartItems,
  newLineItems
) {
  let previousLineItemBundleId = ''

  return newLineItems.map((newLineItem) => {
    const lineItemWIthBundleDisplayOrder = {
      ...newLineItem,
      attributes: [
        {
          key: '_bundleDisplayOrder',
          value: String(
            calculateBundleDisplayOrder(
              newLineItem,
              bundledCartItems,
              previousLineItemBundleId
            )
          ),
        },
        ...(newLineItem.attributes ? newLineItem.attributes : []),
      ],
    }

    previousLineItemBundleId = newLineItem.attributes.find(
      (attr) => attr.key === '_bundleId'
    )?.value

    return lineItemWIthBundleDisplayOrder
  })
}

/**
 *
 * @param lines
 *
 * Loops through each line item creates an array of those bundle ids and then
 * deduplicates them via Set()
 *
 */
export function getUniqueBundleIdsFromNewLineItems(lines): Array<string> {
  const bundleIds: string[] = []

  lines.forEach((line) => {
    return line.attributes.forEach((attr) => {
      if (attr.key === '_bundleId') {
        bundleIds.push(attr.value)
      }
    })
  })

  return [...new Set(bundleIds)] as string[]
}

/**
 *
 * @param bundleItems
 *
 * Finds the bundle display order value based on the first line item inside a bundle
 * that has the _bundleDisplayOrder attribute set.
 *
 */
export function getBundleDisplayOrder(bundleItems: BundleItem[]) {
  let displayOrder = 0

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_bundleDisplayOrder' && !displayOrder) {
        displayOrder = Number(attr.value)
      }
    })
  })

  return displayOrder
}

/**
 *
 * @param bundleItems
 *
 * Finds the bundle sku based on the first line item inside a bundle that has the
 * _bundleSku value set.
 *
 */
export function getBundleSkuFromBundleItems(bundleItems: BundleItem[]) {
  let bundleSku: string | undefined

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_bundleSku') {
        bundleSku = attr.value
      }
    })
  })

  // Fallback to using the SKU of the first item in the bundle
  if (!bundleSku) {
    bundleSku = bundleItems[0].node.merchandise.sku
  }

  return bundleSku
}

/**
 *
 * @param bundleItems
 *
 * Find the checkout info from the bundle item that has the
 * _checkoutInfo value set.
 *
 */
export function getBundleCheckoutInfo(bundleItems: BundleItem[]) {
  let checkoutInfo

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes?.forEach((attr) => {
      if (attr.key === '_checkoutInfo') {
        checkoutInfo = attr.value.split(',')
      }
    })
  })

  return checkoutInfo
}

/**
 *
 * @param bundleItems
 *
 * Find the checkout info from the bundle item that has the
 * _despatchDate value set.
 *
 */
export function getBundleDespatchDate(bundleItems: BundleItem[]) {
  let despatchDate

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_despatchDate') {
        despatchDate = attr.value
      }
    })
  })

  return despatchDate
}

/**
 *
 * @param bundleItems
 *
 * Returns an array of part skus used to create the bundleSku
 *
 */
export function getBundleSkusFromBundleItems(
  bundleItems: BundleItem[]
): string[] {
  return (
    bundleItems
      .map((bundleItem) => {
        return bundleItem.node.merchandise.sku as string
      })
      .filter(Boolean) || []
  )
}

/**
 *
 * @param bundleItems
 *
 * Finds the bundle base sku based on the first line item with the _bundleSku
 * attribute which then pulls the base sku from the merchanise.sku field from the
 * Shopify response.
 *
 */
export function getBundleBaseSkuFromBundleItems(bundleItems: BundleItem[]) {
  let bundleBaseSku: string | undefined

  bundleItems.forEach((bundleItem) => {
    if (bundleItem.node.lineComponents?.length) {
      bundleBaseSku = bundleItem.node.merchandise.sku as string
    } else if (!bundleBaseSku) {
      bundleItem.node.attributes.forEach((attr) => {
        if (attr.key === '_bundleSku') {
          bundleBaseSku = bundleItem.node.merchandise.sku as string
        }
      })
    }
  })

  if (!bundleBaseSku) {
    // Fallback to using the SKU of the first item in the bundle
    bundleBaseSku = bundleItems[0].node.merchandise.sku
  }

  return bundleBaseSku
}

/**
 *
 * @param bundleItems
 *
 * Calculates the bundle cost by combining the cost of each line item in the bundle
 *
 */
export function getBundleCost(bundle: Bundle) {
  // For Shopify bundles just return the cost of the parent line item - the one which has lineComponents
  if (bundle.isShopifyBundle)
    return Number(
      bundle.items.find(
        ({ node: { lineComponents } }) => lineComponents?.length
      )?.node.cost.totalAmount.amount || 0
    )

  // For custom bundles calculate the cost of all items in the bundle
  let bundleCost = 0

  bundle.items.forEach((bundleItem) => {
    bundleCost += Number(bundleItem.node.cost.totalAmount.amount)
  })

  return bundleCost
}

/**
 *
 * @param bundle
 *
 * Calculates the original bundle cost without any discounts
 *
 */
export function getOriginalBundleCost(bundle: Bundle) {
  // For Shopify bundles just return the cost of the parent line item - the one which has lineComponents)
  if (bundle.isShopifyBundle)
    return Number(
      bundle.items.find(
        ({ node: { lineComponents } }) => lineComponents?.length
      )?.node.cost.subtotalAmount.amount || 0
    )

  // For custom bundles calculate the cost of all items in the bundle
  let originalBundleCost = 0

  bundle.items.forEach((bundleItem) => {
    originalBundleCost += Number(bundleItem.node.cost.subtotalAmount.amount)
  })

  return originalBundleCost
}

/**
 *
 * @param bundleItems
 *
 * Calculates the bundle quantity by finding items with the _bundleSku attribute and
 * returning the quantity field value from the Shopify response.
 *
 */
export function getBundleQuantity(bundleItems: BundleItem[]) {
  let bundleQuantity = 0

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_bundleSku') {
        bundleQuantity = bundleItem.node.quantity
      }
    })
  })

  // Fallback to using the quantity of the first item in the bundle
  if (!bundleQuantity) {
    bundleQuantity = bundleItems[0].node.quantity
  }

  return bundleQuantity
}

export function getExpectedCartAttributes(): CartAttributes {
  const { $amplitude } = useNuxtApp()
  const amplitudeDevideId = (
    $amplitude as { getDeviceId: () => string }
  )?.getDeviceId()

  return [
    {
      key: '_amplitudeDeviceId',
      value: amplitudeDevideId || '',
    },
  ]
}

export function buildCartItems(
  customisedProduct: any,
  metaData: CartAttributes = []
) {
  const bundleId = Math.random().toString(36).substring(7)

  const baseVariantId = customisedProduct.product.commerceId

  const cartItemCommerceOptions = [] as {
    type?: string
    commerceId: string
  }[]

  const despatchDate =
    customisedProduct.delayedDespatchDate ||
    customisedProduct.preorderDate ||
    null

  const checkoutInfo = [
    customisedProduct.product?.showPackagingOptions && 'litePackagingAvailable',
    customisedProduct.procuct?.defaultPackagingOption === 'lite' &&
      'defaultLitePackaging',
    customisedProduct.isPrescription && 'isPrescription',
    customisedProduct.isOnPreorder &&
      customisedProduct.preorderDate &&
      'isPreorder',
    customisedProduct.delayedDespatchDate && 'isDelayedDespatch',
  ]
    .filter((item) => item)
    ?.join(',')

  Object.values(customisedProduct.choices).forEach((choice) => {
    if (choice.commerceId) {
      cartItemCommerceOptions.push({
        type: 'variant',
        commerceId: choice.commerceId,
      })
    }
  })

  // If there are no choices then the product is a limited edition/simple product
  // and we should use the product id as the commerce id
  let isBundle = true
  if (!cartItemCommerceOptions.length) {
    isBundle = false
    cartItemCommerceOptions.push({
      type: 'product',
      commerceId: customisedProduct.product.commerceId,
    })
  }

  return cartItemCommerceOptions.map(({ commerceId }, index) => {
    const attributes = [] as CartAttributes

    if (isBundle) {
      attributes.push({
        key: '_bundleId',
        value: bundleId,
      })

      // We want to add more detailed attributes to the first item in the bundle
      if (index === 0) {
        // Used by `sg-shopify-external` Cart Transform to determine what
        // variant should be used to create the bundle
        attributes.push({
          key: '_baseVariantId',
          value: baseVariantId,
        })

        attributes.push({
          key: '_bundleSku',
          value: customisedProduct.sku,
        })
      }
    }

    if (index === 0) {
      // Used by `sg-shopify-external` checkout UI extensions
      if (checkoutInfo)
        attributes.push({
          key: '_checkoutInfo',
          value: checkoutInfo,
        })
      if (despatchDate)
        attributes.push({
          key: '_despatchDate',
          value: despatchDate,
        })

      // Add metadata only to the first item in the bundle
      attributes.concat(metaData)
    }

    return {
      merchandiseId: commerceId,
      quantity: 1,
      attributes: attributes.filter((a) => a),
    }
  })
}

export async function getCouponCode(
  code: string
): Promise<CodeDiscountNodeByCode | null> {
  try {
    const response = await useFetch<CodeDiscountNodeByCode>(
      `/api/discounts/code/${code}`
    )
    const coupon = response?.data?.value || null
    if (coupon?.id) {
      return coupon
    } else {
      return null
    }
  } catch (error: any) {
    captureException(error)
  }
  return null
}

export async function isValidGiftCard(code: string): Promise<boolean> {
  const response = await useFetch<any>(`/api/giftcards/code/${code}`)

  return response?.data?.value
}

/**
 * @param customerGets
 */
function buildDiscountSummary(customerGets: CustomerGets): DiscountSummary {
  return {
    discountType: customerGets.value.effect.type,
    discountPercentageOff: customerGets.value.effect.type.percentage || 0,
    discountAmountOff: Number(customerGets.value.effect.amount?.amount) || 0,
    discountIsFree: customerGets.value.effect.percentage === 1,
  }
}

/**
 * @param customerGets
 */
function buildFreeGiftOptions(
  customerGets: CustomerGets
): Array<FreeGiftOptions | []> {
  if (customerGets.items.type === 'DiscountProducts') {
    const discountedOptions: Array<FreeGiftOptions> = []

    if (customerGets.items.products.nodes.length) {
      discountedOptions.push(
        ...customerGets.items.products.nodes.map((node) => ({
          productId: node.id,
          options: node.variants.nodes.map((variant) => {
            const baseSku = buildFreeGiftBaseSku(variant.sku)

            if ('case' === baseSku) return null

            return {
              variantId: variant.id,
              baseSku: buildFreeGiftBaseSku(variant.sku),
              sku: buildFreeGiftVariantSku(variant.sku),
            }
          }).filter(a => a),
        }))
      )
    }

    if (customerGets.items.productVariants.nodes.length) {
      type Accumulator = {
        [productId: string]: {
          id: string
          sku: string
          product: { id: string }
        }[]
      }

      const groupedVariants: Accumulator =
        customerGets.items.productVariants.nodes.reduce(
          (acc: Accumulator, curr): Accumulator => {
            (acc[curr.product.id] = acc[curr.product.id] || []).push(curr)
            return acc
          },
          {}
        )

      discountedOptions.push(
        ...Object.entries(groupedVariants).map(([productId, variants]) => {
          return {
            productId,
            options: variants.map((variant) => ({
              variantId: variant.id,
              baseSku: buildFreeGiftBaseSku(variant.sku),
              sku: buildFreeGiftVariantSku(variant.sku),
            })),
          }
        })
      )
    }

    return discountedOptions.filter(discountOption => discountOption.options.length)
  }

  return []
}

function buildFreeGiftVariantSku(sku: string) {
  /**
   *
   * Case products are uniquely set up requiring two separate skus
   * to form a tangible product. When the ct product is selected we
   * must manually prefix 'case' here as it is not possible to find this
   * information through Shopify as the 'ct' product and 'case' are
   * held separately and unrelated to one another. The addition of more
   * metadata attached to the product could address this in the future.
   *
   */
  if (sku.includes('ct_')) {
    return 'case-' + sku
  }

  return sku
}

function buildFreeGiftBaseSku(sku: string) {
  if (sku.includes('ct_')) {
    return 'case'
  }

  else if (sku.includes('_')) {
    return sku.split('_')[0]
  }

  return sku
}

export function getAllValidGiftDiscounts(
  automaticDiscounts: AutomaticDiscount[]
): DiscountAutomaticBxgy[] {
  return automaticDiscounts.filter(
    (discount: AutomaticDiscount): discount is DiscountAutomaticBxgy => {
      if (discount.automaticDiscount.discountClass === 'PRODUCT') {
        // The products a customer gets are part of a manually defined product list within the discount
        if (
          discount.automaticDiscount.customerGets.items?.type ===
          'DiscountProducts'
        ) {
          return (
            !!discount.automaticDiscount.customerGets.items.products.nodes
              .length ||
            !!discount.automaticDiscount.customerGets.items.productVariants
              .nodes.length
          )
        }

        // If it is a collection of items a customer must buy
        if (
          discount.automaticDiscount.customerBuys.items.type ===
          'DiscountCollections'
        ) {
          // Keep the discount if the collection has a non-zero array of products defined in the collection
          return !!discount.automaticDiscount.customerBuys.items.collections
            ?.nodes.length
        }

        // The items a customer must buy have not been defined therefore nothing qualifies for the promo and can be not returned
        return false
      }

      return false
    }
  )
}

export function getAllDiscountsForProduct(
  customisedProduct: any,
  getAllValidGiftDiscounts: DiscountAutomaticBxgy[]
): DiscountsForProduct | {} {
  /**
   *
   * Discounts for products can be free gifts, percentage of other products or even a percentage of
   * their own product.
   *
   * This function returns the qualifying product discounts/gifts for a customised product.
   *
   * Each discount can have a qualifying list of product parts that a customer must purchase to qualify
   * for the free gift.
   *
   *
   * =======================================================================================================
   * Breakdown
   * =======================================================================================================
   *
   *
   * 1. Filter discounts to get only automatic discounts with discountClass set to "PRODUCT" and that have a
   * customerGets value which has products or productVariants in the items array and customerBuys list has a
   * set of product options that trigger the discount. This is stored in getAllValidGiftDiscounts.
   *
   *
   *
   * 2. Search through the customerBuys.items to check if any of the current customised product parts
   * exist in that array from Shopify. The items can be defined as either part of a collection ("DiscountCollection") or as
   * part of a list of products ("DiscountProducts") that is manually defined per specific discount.
   *
   * The items in the customerBuys array must also pass the thresholds set by the discount:
   *
   * If the discount has a price threshold then the price of the customised product must be over that discount price.
   *
   * If the discount has a quantity threshold then the total quantity of any items in the current customised product
   * that match with any items in the customerBuys must be totaled and checked against the threshold for the discount.
   *
   *
   *
   * 3. If we found matching parts and the current selections in customised product pass the discount thresholds
   * then return the products and product variants a customers "gets" as an array of useful and easy to access
   * information that can be consumed within pages and components.
   */

  const availableDiscounts = getAllValidGiftDiscounts
    .map((validGiftDiscount) => {
      const matchedItems: Array<{
        id: string
        sku: string
        price: string
      }> = []
      let thresholdForDiscountMet = false

      if (
        validGiftDiscount.automaticDiscount.customerBuys.items.type ===
        'DiscountCollections'
      ) {
        validGiftDiscount.automaticDiscount.customerBuys.items.collections?.nodes.forEach(
          (collectionItem) => {
            return collectionItem.products.nodes.forEach(
              (collectionProductItem) => {
                collectionProductItem.variants.nodes.forEach(
                  (collectionProductItemVariant) => {
                    if (customisedProduct.isLimitedEdition) {
                      if (
                        customisedProduct.product.commerceId ===
                        collectionProductItemVariant.id
                      ) {
                        matchedItems.push(collectionProductItemVariant)
                      }
                    } else {
                      Object.values(customisedProduct.choices).forEach(
                        (choice: any) => {
                          if (
                            choice.commerceId ===
                            collectionProductItemVariant.id
                          ) {
                            matchedItems.push(collectionProductItemVariant)
                          }
                        }
                      )
                    }
                  }
                )
              }
            )
          }
        )
      } else if (
        validGiftDiscount.automaticDiscount.customerBuys.items.type ===
        'DiscountProducts'
      ) {
        // if all variants for a product are selected then use the products field
        if (
          validGiftDiscount.automaticDiscount.customerBuys.items.products?.nodes
            .length
        ) {
          validGiftDiscount.automaticDiscount.customerBuys.items.products.nodes.forEach(
            (product) => {
              product.variants.nodes.forEach((productVariant) => {
                /**
                 *
                 * If only one variant is selected as the thing a customer
                 * must buy or the product selected is a limited edition then treat it as any
                 * version of the base product selected qualifies for the free gift.
                 *
                 * e.g. renegades, renegades-le_*
                 *
                 **/
                if (
                  customisedProduct.isLimitedEdition ||
                  product.variants.nodes.length === 1
                ) {
                  if (
                    customisedProduct.product.commerceId === productVariant.id
                  ) {
                    matchedItems.push(productVariant)
                  }
                } else {
                  Object.values(customisedProduct.choices).forEach(
                    (choice: any) => {
                      if (choice.commerceId === productVariant.id) {
                        matchedItems.push(productVariant)
                      }
                    }
                  )
                }
              })
            }
          )
        }

        // else only some variants for a products are selected then use the productVariants field
        else if (
          validGiftDiscount.automaticDiscount.customerBuys.items.productVariants
            ?.nodes.length
        ) {
          validGiftDiscount.automaticDiscount.customerBuys.items.productVariants.nodes.forEach(
            (productVariant) => {
              Object.values(customisedProduct.choices).forEach(
                (choice: any) => {
                  if (choice.commerceId === productVariant.id) {
                    matchedItems.push(productVariant)
                  }
                }
              )
            }
          )
        }
      }

      /**
       * If there are no matched items then there is no discount applicable to the customer
       * selected product or any of it's parts.
       */
      if (!matchedItems.length) return {}

      /**
       * Check sum matches or passes purchase quantity threshold or total price threshold
       * matches or passes based on the total price of the matched items.
       */
      if (
        validGiftDiscount.automaticDiscount.customerBuys.value.type ===
        'DiscountQuantity'
      ) {
        thresholdForDiscountMet =
          Number(
            validGiftDiscount.automaticDiscount.customerBuys.value.quantity
          ) === matchedItems.length
      } else if (
        validGiftDiscount.automaticDiscount.customerBuys.value.type ===
        'DiscountPurchaseAmount'
      ) {
        const costTotalOfAllParts = matchedItems.reduce((acc, curr) => {
          acc += Number(curr.price)
          return acc
        }, 0)

        thresholdForDiscountMet =
          costTotalOfAllParts >=
          Number(validGiftDiscount.automaticDiscount.customerBuys.value.amount)
      }

      return {
        matchedItems,
        thresholdForDiscountMet,
        discountSummary: buildDiscountSummary(
          validGiftDiscount.automaticDiscount.customerGets
        ),
        customerGets: buildFreeGiftOptions(
          validGiftDiscount.automaticDiscount.customerGets
        ),
      }
    })
    .filter((a) => a)

  if (!availableDiscounts.length) return {}

  return {
    availableDiscounts,
    anyDiscountThresholdPassed: !!availableDiscounts.find(
      (availableDiscount) => availableDiscount?.thresholdForDiscountMet
    ),
    anyDiscountIsFree: !!availableDiscounts.find(
      (availableDiscount) => availableDiscount?.discountSummary?.discountIsFree
    ),
  }
}

export function getUpdateProductQuantityPayload(bundles: any, incrementBy = 1) {
  return bundles.flatMap((bundle: any) => {
    return bundle.isShopifyBundle
      ? [
          {
            id: bundle.bundleId,
            quantity: bundle.summary.bundleQuantity + incrementBy,
          },
        ]
      : bundle.items.map((item: any) => {
          return {
            id: item.node.id,
            merchandiseId: item.node.merchandise.id,
            quantity: incrementBy,
          }
        })
  })
}
